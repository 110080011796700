<template>
  <div v-if="deferredPrompt" class="w-100 h-100">
    <div class="grey-bg"></div>
    <div class="d-flex justify-content-center align-items-center">
      <div v-bind:style="{ 'background-image' : 'url(' + require(`../../brand/${brandConfig.brand}/assets/popups/pop_up-pwa.webp`) + ')'}">
      <div @click="onCloseClicked()" class="position-relative w-100"><img class="btn-close-popup"
                                                                          :src="require(`../../brand/${this.brandConfig.brand}/assets/popups/btn_close.webp`)"
                                                                          alt=""></div>
      <img ref="addBtn" @click="clickCallback()" class="w-50"
           :src="require(`../../${this.brandConfig.brand}/assets/popups/btn_pwa.webp`)" alt="">
    </div>
  </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import TriviaDataService from "@/services/triviaDataService";

export default {
  name: "AddAppToHome",
  computed: {
    ...mapState(['brandConfig', 'deferredPrompt', 'user'])
  },
  methods: {
    ...mapMutations(['setShowAddToHomeScreen', 'setDeferredPrompt', 'setShowSubscriptionPopup']),
    onCloseClicked() {
      this.showUserSubscriptionPopup();
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Add analyticcs event
          this.$gtag.event('add_to_home_screen')
        }
        this.setDeferredPrompt(null);
      })
      this.setShowAddToHomeScreen(false);
    },
    async showUserSubscriptionPopup() {
      const showPopup = await TriviaDataService.showPopup(this.user.msisdn, this.user.serviceId, "WelcomeSubscription");
      if (showPopup.data) this.setShowSubscriptionPopup(true);
      else {
        this.setDeferredPrompt(null);
        this.setShowSubscriptionPopup(false);
      }
    },
  },
}
</script>

<style scoped>
#main-container {
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  margin-top: 10%;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
  height: 478px;
  width: 339px;
}

.grey-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: #b7b7b7;
  opacity: 0.8;
}

.btn-close-popup {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: -206px;
  z-index: 11;
}
</style>
