<template>
  <div class="d-flex flex-column justify-content-center align-items-end mx-2">
    <img v-if="!hasNoGameLogo" class="game-logo-icon" :src="require(`../../brand/${brandConfig.brand}/assets/game-logo.webp`)">
    <div v-if="userStats" class="coins-container">
      <div v-if="!hasNoCoins" class="d-flex">
        <div class="coins-text"> {{ userStats.TotalCoins }}</div>
        <img class="coins-icon" :src="require('../../assets/icons/coin.png')">
      </div>
    </div>
    <img v-if="this.getServiceIdMapNumber(user.serviceId) === 1" class="vip-logo"
         :src="require(`../../brand/${brandConfig.brand}/assets/icons/free-icon.webp`)">
    <img v-if="this.getServiceIdMapNumber(user.serviceId) === 2" class="vip-logo"
         :src="require(`../../brand/${brandConfig.brand}/assets/icons/vip-icon.webp`)">
    <div v-if="hasNoCoins" class="has-no-coins">

    </div>
  </div>
</template>

<script>
import {serviceIdMap} from "@/config";
import {mapState} from "vuex";

export default {
  name: "PlayerServiceBoxTwo",
  props: ['hasNoGameLogo', 'hasNoCoins'],
  computed: {
    ...mapState(['user', 'brandConfig', 'userStats'])
  },
  methods: {
    getServiceIdMapNumber(service) {
      return serviceIdMap.get(service)
    },
    getColoursByServiceId(serviceId, type) {
      if (this.getServiceIdMapNumber(serviceId) === 1) return `primary-free-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 2) return `primary-vip-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 3) return `primary-vvip-${type}-colour`
    },
  }
}
</script>

<style scoped>
.game-logo-icon {
  width: 115px;
  height: auto;
}
.coins-icon {
  height: 40px;
  width: auto;
  font-size: 14px;
}

.coins-text {
  color: #000F56;
  font-weight: 700;
  margin-right: 8px;
  font-size: 30px;
}

.has-no-coins {
  width: 125px;
  height: 45px;
}

.coins-container {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 125px;
}

.vip-logo {
  width: 75px;
  height: auto;
}
</style>
