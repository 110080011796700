import {createStore} from 'vuex'

export default createStore({
    state: {
        showLeaderBoardPopup: false,
        isLoading: false,
        displayBonusRoundPop: false,
        displayMyMuzePopup: undefined,
        brandConfig: undefined,
        user: undefined,
        msisdn: undefined,
        serviceId: undefined,
        howToPlayCarousel: 'free',
        userPlayedToday: false,
        subscriptionType: undefined,
        editUsernameRouting: undefined,
        campaignId: undefined,
        productId: 18,
        gameId: undefined,
        gameState: {GameComplete: false},
        categoryGameState: [],
        userStats: undefined,
        questionNumber: undefined,
        userQuestions: undefined,
        questionCategoryId: undefined,
        randomizeQuestions: undefined,
        showAddToHomeScreen: false,
        showSubscriptionPopup: false,
        deferredPrompt: null,
        token: undefined,
        encryptedMsisdn: undefined,
        evinaToken: undefined,
        googleClickId: undefined,
    },
    mutations: {
        setProductId(state, productId) {
            state.productId = productId
        },
        setShowLeaderBoardPopup(state, showLeaderBoardPopup) {
            state.showLeaderBoardPopup = showLeaderBoardPopup
        },
        setDisplayMyMuzePopup(state, displayMyMuzePopup) {
            state.displayMyMuzePopup = displayMyMuzePopup
        },
        setDisplayBonusRoundPop(state, displayBonusRoundPop) {
            state.displayBonusRoundPop = displayBonusRoundPop
        },
        changeUserServiceId(state, serviceId) {
            state.user.serviceId = serviceId
        },
        setCategoryGameState(state, categoryGameState) {
            state.categoryGameState = categoryGameState
        },
        setGameState(state, gameState) {
            if (gameState) state.gameState = gameState;
            else state.gameState = {GameComplete: false};
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setUser(state, user) {
            state.user = user
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setEncryptedMsisdn(state, encryptedMsisdn) {
            state.encryptedMsisdn = encryptedMsisdn
        },
        setToken(state, token) {
            state.token = token
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setHowToPlayCarousel(state, howToPlayCarousel) {
            state.howToPlayCarousel = howToPlayCarousel
        },
        setUserPlayedToday(state, userPlayedToday) {
            state.userPlayedToday = userPlayedToday
        },
        setSubscriptionType(state, subscriptionType) {
            state.subscriptionType = subscriptionType
        },
        setEditUsernameRouting(state, editUsernameRouting) {
            state.editUsernameRouting = editUsernameRouting
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setClickId(state, clickId) {
            state.clickId = clickId
        },
        setGameId(state, gameId) {
            state.gameId = gameId
        },
        setUserStats(state, userStats) {
            state.userStats = userStats
        },
        setQuestionNumber(state, questionNumber) {
            state.questionNumber = questionNumber
        },
        setUserQuestions(state, userQuestions) {
            state.userQuestions = userQuestions
        },
        setQuestionCategoryId(state, questionCategoryId) {
            state.questionCategoryId = questionCategoryId
        },
        setRandomizeQuestions(state, randomizeQuestions) {
            state.randomizeQuestions = randomizeQuestions
        },
        setShowAddToHomeScreen(state, showAddToHomeScreen) {
            state.showAddToHomeScreen = showAddToHomeScreen
        },
        setShowSubscriptionPopup(state, showSubscriptionPopup) {
            state.showSubscriptionPopup = showSubscriptionPopup
        },
        setDeferredPrompt(state, deferredPrompt) {
            state.deferredPrompt = deferredPrompt
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
    },
    actions: {},
    modules: {}
})
