import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    depService: `${process.env.VUE_APP_DEP_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    authHeaderUnsubscribe: `${process.env.VUE_APP_AUTHORIZATION_HEADER_UNSUBSCRIBE}`,
    azureBlob: `${process.env.VUE_APP_AZURE_BLOB}`,
    myMuzeUrl : `${process.env.VUE_APP_MY_MUZE_URL}`,
    nodeEnv: `Prod`
}

export const developmentConfig = {
    depService: `${process.env.VUE_APP_DEP_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    authHeaderUnsubscribe: `${process.env.VUE_APP_AUTHORIZATION_HEADER_UNSUBSCRIBE}`,
    azureBlob: `${process.env.VUE_APP_AZURE_BLOB}`,
    myMuzeUrl : `${process.env.VUE_APP_MY_MUZE_URL}`,
    nodeEnv: `Prod`
}

export const stagingConfig = {
    depService: `${process.env.VUE_APP_STAGING_DEP_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    triviaService: `${process.env.VUE_APP_STAGING_TRIVIA_SERVICE}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    authHeaderUnsubscribe: `${process.env.VUE_APP_AUTHORIZATION_HEADER_UNSUBSCRIBE}`,
    azureBlob: `${process.env.VUE_APP_AZURE_BLOB}`,
    myMuzeUrl : `${process.env.VUE_APP_MY_MUZE_URL}`,
    nodeEnv: `Staging`
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'staging'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return developmentConfig
    if (environment === 'production') return productionConfig
}
