<template>
  <div v-if="!isBradVodacom(brandConfig.brand)" class="d-flex w-100 justify-content-between">
    <img class="background-icon"
         :src="require(`../../brand/${brandConfig.brand}/assets/backgrounds/menu-bg-image.webp`)">
    <div class="vip-container">
      <PlayerServiceBoxTwo/>
    </div>
  </div>
  <div v-if="!showMtnPackages && !selectedPackage"
       class="d-flex flex-column align-items-center text-center pt-1 col-12">
    <img v-if="isBradVodacom(brandConfig.brand)" class="game-logo col-5 mt-3"
         :src="require(`../../brand/${brandConfig.brand}/assets/game-logo.webp`)">
    <img v-if="isBradVodacom(brandConfig.brand)" class="user-avatar col-4"
         :src="require(`../../brand/${brandConfig.brand}/assets/avatars/default/avatar-${userAvatar}-unselected.webp`)">
    <div v-if="!unsubscribeContainer">
      <div v-if="isBradVodacom(brandConfig.brand)" class="header-text"
           :class="getColoursByServiceId(user.serviceId, 'text')">MANAGE SUBSCRIPTION
      </div>
      <h5 class="header-text-mtn px-5" v-if="!isBradVodacom(brandConfig.brand)">Manage your Mzansi
        Rhythm Subscription </h5>
      <div v-if="getServiceIdMapNumber(user.serviceId) === 1" class="d-flex flex-column align-items-center">
        <div v-if="isBradVodacom(brandConfig.brand)" class="manage-subscription-info col-9 mt-3">You are currently on
          the free version of {{ brandConfig.gameTitle }}. Join VIP or VVIP for extra questions and more prizes.
        </div>
        <div v-if="!isBradVodacom(brandConfig.brand)" class="manage-subscription-info col-9 mt-3">You are currently on
          the free version of {{ brandConfig.gameTitle }}. Join VIP for extra questions and more prizes.
        </div>
        <div v-if="isBradVodacom(brandConfig.brand)" :class="getColoursByServiceId(user.serviceId, 'bg')"
             @click="subscribeClicked('opt-in', 'vip')"
             class="d-flex flex-row align-items-center justify-content-center manage-subscription-container mt-4 col-8 primary-text primary-bg-colour">
          SUBSCRIBE
          TO VIP <span class="price-point-text">| R3/DAY</span>
        </div>
        <div v-if="isBradVodacom(brandConfig.brand)" :class="getColoursByServiceId(user.serviceId, 'bg')"
             @click="subscribeClicked('opt-in', 'vvip')"
             class="d-flex flex-row align-items-center justify-content-center manage-subscription-container mt-2 col-8 primary-text primary-bg-colour">
          SUBSCRIBE
          TO VVIP <span class="price-point-text">| R7/Week</span>
        </div>
        <div v-if="!isBradVodacom(brandConfig.brand)" @click="chagePaymentPlanClicked()"
             class="d-flex flex-row align-items-center justify-content-center manage-subscription-container-mtn yellow-bg mt-4 col-8">
          CHANGE PAYMENT PLAN
        </div>
      </div>

      <div v-if="getServiceIdMapNumber(user.serviceId) === 2 && isBradVodacom(brandConfig.brand)"
           class="d-flex flex-column align-items-center">
        <div class="manage-subscription-info col-9 mt-1">You are currently subscribed to {{ brandConfig.gameTitle }} VIP
          at R3/day
        </div>
        <div class="manage-subscription-info col-9 mt-2">Upgrade to VVIP and stand a chance to WIN your share of R10 000
          in cash every month.
        </div>
        <div class="manage-subscription-info col-9 mt-2">To unsubscribe dial *135*997# or click the unsubscribe button
          below.
        </div>
        <div @click="subscribeClicked('opt-in', 'vvipUpgrade')"
             class="d-flex flex-row align-items-center justify-content-center manage-subscription-container mt-2 col-8 primary-text"
             :class="getColoursByServiceId(user.serviceId, 'bg')">UPGRADE
          TO
          VVIP <span class="price-point-text">| R7/Week</span>
        </div>
      </div>
      <div v-if="getServiceIdMapNumber(user.serviceId) === 3" class="d-flex flex-column align-items-center">
        <div class="manage-subscription-info col-9 mt-3">You are currently subscribed to {{ brandConfig.gameTitle }}
          VVIP at R7/week
        </div>
        <div class="manage-subscription-info col-9 mt-3">To unsubscribe dial *135*997# or click the unsubscribe button
          below.
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="unsubscribeContainer">
      <div :class="getColoursByServiceId(user.serviceId, 'text')" class="header-text">UNSUBSCRIBE</div>
      <div class="manage-subscription-info col-9 mt-3">Are you sure you want to unsubscribe from
        {{ brandConfig.gameTitle }}?
      </div>
      <div @click="confirmUnsubscribeClicked()"
           class="d-flex flex-column justify-content-center unsubscribe-container mt-4 col-8 primary-text"
           :class="getColoursByServiceId(user.serviceId, 'bg')">YES
        UNSUBSCRIBE
      </div>
    </div>
    <div v-if="isBradVodacom(brandConfig.brand)" @click="routeToPage('landing')"
         class="d-flex flex-column justify-content-center manage-subscription-container mt-2 col-8 primary-text"
         :class="getColoursByServiceId(user.serviceId, 'bg')">
      RETURN HOME
    </div>
    <div v-if="!isBradVodacom(brandConfig.brand)" @click="goBackClicked()"
         class="d-flex flex-column justify-content-center manage-subscription-container-mtn black-bg mt-2 col-8 primary-text">
      GO BACK
    </div>
    <div v-if="!unsubscribeContainer && getServiceIdMapNumber(user.serviceId) !== 1"
         :class="getColoursByServiceId(user.serviceId, 'text')" @click="unsubscribeClicked()"
         class="d-flex flex-column justify-content-center unsubscribe-container mt-2 col-8 tertiary-text">
      UNSUBSCRIBE
    </div>
  </div>

  <div v-if="showMtnPackages" class="d-flex flex-column align-items-center text-center pt-1 col-12">
    <h5 class="header-text-mtn px-5" v-if="!isBradVodacom(brandConfig.brand)">Please choose a
      subscription package</h5>
    <div v-if="!isBradVodacom(brandConfig.brand)" class="manage-subscription-info col-9 mt-3">Choose a subscription
      package to join Mzansi Rhythm
    </div>
    <div v-if="!isBradVodacom(brandConfig.brand)" @click="onPackageClicked('R5/Day', 2)"
         class="d-flex flex-column justify-content-center manage-subscription-container-mtn  mt-2 col-8"
         v-bind:class="getPricePointClass(2)">
      R5/Day
    </div>
    <div v-if="!isBradVodacom(brandConfig.brand)" @click="onPackageClicked('R15/Week', 3)"
         class="d-flex flex-column justify-content-center manage-subscription-container-mtn  mt-2 col-8"
         v-bind:class="getPricePointClass(3)">
      R15/week
    </div>
    <div v-if="!isBradVodacom(brandConfig.brand)" @click="onPackageClicked('R30/Month', 4)"
         class="d-flex flex-column justify-content-center manage-subscription-container-mtn  mt-2 col-8"
         v-bind:class="getPricePointClass(4)">
      R30/Month
    </div>
    <h5 class="text-center mt-3" @click="onCancelClicked()"><u>CANCEL</u></h5>
  </div>

  <div v-if="selectedPackage" class="d-flex flex-column align-items-center text-center pt-1 col-12">
    <h5 class="header-text-mtn px-5" v-if="!isBradVodacom(brandConfig.brand)">Please confirm your subscription
      to Mzansi Rhythm VIP</h5>
    <div v-if="!isBradVodacom(brandConfig.brand)" class="manage-subscription-info col-9 mt-3">{{ selectedPackage }}
      Subscription service
      FIRST DAY FREE
    </div>
    <div v-if="!isBradVodacom(brandConfig.brand)" @click="onConfirmPackageClicked()"
         class="d-flex flex-column justify-content-center manage-subscription-container-mtn yellow-bg  mt-2 col-8">
      Yes
    </div>
    <h5 class="text-center mt-3" @click="onCancelClicked()"><u>CANCEL</u></h5>
  </div>
  <FooterNav/>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import DcbService from "@/services/dcbService";
import setUserSessionData from "@/functions/setUserSessionData";
import {isVodacom, serviceIdMap} from "@/config";
import FooterNav from "@/views/components/FooterNav";
import PlayerServiceBoxOne from "@/views/components/PlayerServiceBoxOne";
import PlayerServiceBoxTwo from "@/views/components/PlayerServiceBoxTwo";
import DepService from "@/services/depService";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "ManageSubscription",
  components: {
    FooterNav,
    PlayerServiceBoxOne,
    PlayerServiceBoxTwo
  },
  data() {
    return {
      userAvatar: undefined,
      unsubscribeContainer: false,
      buttonBorder: '.2px solid #C10041',
      showMtnPackages: false,
      userPricePoint: undefined,
      selectedPackage: undefined,
      selectedServiceId: undefined,
      subscriptionId: undefined
    }
  },
  computed: {
    ...mapState(['brandConfig', 'user', 'productId']),
  },
  methods: {
    ...mapMutations(['setSubscriptionType', 'setUser', 'setGameState', 'setCategoryGameState', 'setIsLoading']),
    async onConfirmPackageClicked() {
      this.setIsLoading(true)
      const response = await DepService.subscribeUser(this.user.msisdn, this.selectedServiceId, this.subscriptionId, this.productId, 0, null);
      this.setIsLoading(false)
      if (!response.data.result) return this.$router.push(`/error/${response.data.reason}`)
      if (response.data.result) {
        this.setUser(response.data.user)
        this.$router.push(`/profile`)
      }
    },
    async updateUser() {
      const user = isVodacom(this.brandConfig.brand) ? (await DcbService.getUserViaProductId(this.user.msisdn, this.productId)).data : (await DepService.getUserViaProductId(this.user.msisdn, this.productId)).data.user;
      this.setUser(user)
    },
    goBackClicked() {
      this.$router.go(-1)
    },
    getCorrectServiceId(pricePoint) {
      if (pricePoint === 'R5/Day' && environmentConfig.nodeEnv === 'Prod') return 10588
      if (pricePoint === 'R15/eek' && environmentConfig.nodeEnv === 'Prod') return 10589
      if (pricePoint === 'R30/Month' && environmentConfig.nodeEnv === 'Prod') return 10590
      if (pricePoint === 'R5/Day' && environmentConfig.nodeEnv === 'Staging') return 45747
      if (pricePoint === 'R15/Week' && environmentConfig.nodeEnv === 'Staging') return 45748
      if (pricePoint === 'R30/Month' && environmentConfig.nodeEnv === 'Staging') return 45749
    },
    async onPackageClicked(pricePoint, pricePointId) {
      if (this.getServiceIdMapNumber(this.user.serviceId) === pricePointId) return
      const serviceId = this.getCorrectServiceId(pricePoint)
      this.selectedServiceId = serviceId;
      const response = await DepService.doiOne(this.user.msisdn, serviceId, this.productId)
      if (response.data.user) this.subscriptionId = response.data.user.subscription_id
      if (!response.data.user) return this.$router.push(`/error/${response.data.reason}`)
      if (pricePoint === 'R5/Day' && this.getServiceIdMapNumber(this.user.serviceId) === 2) return
      if (pricePoint === 'R15/Week' && this.getServiceIdMapNumber(this.user.serviceId) === 3) return
      if (pricePoint === 'R30/Month' && this.getServiceIdMapNumber(this.user.serviceId) === 4) return
      this.showMtnPackages = false
      this.selectedPackage = pricePoint
    },
    onCancelClicked() {
      this.$router.go(-1)
    },
    chagePaymentPlanClicked() {
      this.showMtnPackages = true
    },
    isBradVodacom(brand) {
      return isVodacom(brand)
    },
    getPricePointClass(pricePointId) {
      if (this.getServiceIdMapNumber(this.user.serviceId) === 1) return 'yellow-border'
      if (this.getServiceIdMapNumber(this.user.serviceId) === pricePointId) return 'yellow-bg'
      else return 'yellow-border'

    },
    setButtonBorder() {
      if (this.getServiceIdMapNumber(this.user.serviceId) === 1 && isVodacom(this.brandConfig.brand)) this.buttonBorder = '.2px solid #C10041'
      if (this.getServiceIdMapNumber(this.user.serviceId) === 1 && !isVodacom(this.brandConfig.brand)) this.buttonBorder = '.2px solid #7C0096'
      if (this.getServiceIdMapNumber(this.user.serviceId) === 2 && isVodacom(this.brandConfig.brand)) this.buttonBorder = '.2px solid #007FB7'
      if (this.getServiceIdMapNumber(this.user.serviceId) === 3 && isVodacom(this.brandConfig.brand)) this.buttonBorder = '.2px solid #E60000'
      if (this.getServiceIdMapNumber(this.user.serviceId) > 1 && !isVodacom(this.brandConfig.brand)) this.buttonBorder = '.2px solid #FFCB05'
      if (this.getServiceIdMapNumber(this.user.serviceId) === 3) this.buttonBorder = '.2px solid #E60000'
    },

    getServiceIdMapNumber(service) {
      return serviceIdMap.get(service)
    },

    getColoursByServiceId(serviceId, type) {
      if (this.getServiceIdMapNumber(serviceId) === 1) return `primary-free-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 2) return `primary-vip-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 3) return `primary-vvip-${type}-colour`
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    },
    getUserAvatar() {
      this.userAvatar = this.user.avatar
    },
    async subscribeClicked(pageRoute, subscriptionType) {
      const service = subscriptionType === "vip" ? "vc-fanclash-beatthejams-01" : "vc-fanclash-beatthejams-02";
      await DcbService.doiOne(this.user.msisdn, service);
      this.setSubscriptionType(subscriptionType)
      this.routeToPage(pageRoute)
    },
    unsubscribeClicked() {
      this.unsubscribeContainer = !this.unsubscribeContainer
    },
    async confirmUnsubscribeClicked() {
      this.setIsLoading(true);
      await this.updateUser();
      const sessionId = localStorage.getItem("sessionId");
      if (isVodacom(this.brandConfig.brand)) await DcbService.doiCancel(this.user.msisdn, this.user.serviceId);
      if (isVodacom(this.brandConfig.brand)) await DcbService.unSubscribeUser(this.user.msisdn, this.user.serviceId, false, sessionId);
      if (!isVodacom(this.brandConfig.brand)) await DepService.unSubscribeUser(this.user.subscriptionId);
      const freePlayService = this.brandConfig.freePlayServiceId;
      const user = isVodacom(this.brandConfig.brand) ? (await DcbService.getUserViaProductId(this.user.msisdn, this.productId)).data : (await DepService.getUserViaProductId(this.user.msisdn, this.productId)).data.user;
      setUserSessionData(this.user.msisdn, freePlayService);
      this.setUser(user);
      this.setIsLoading(false);
      this.$router.push('/login');
    },
  },
  beforeMount() {
    this.getUserAvatar();
    this.setButtonBorder();
    if (this.brandConfig.brand === 'mtn-za-en') this.showMtnPackages = true
    console.log(this.user)
  }
}
</script>

<style scoped>
.header-text-mtn {
  color: #000F56;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09px;
}

.background-icon {
  width: 230px;
}

.back-icon, .hamburger-icon {
  position: absolute;
  top: 70px
}

.hamburger-icon {
  right: 20px;
}

.back-icon {
  left: 20px;
}

.hamburger-icon, .back-icon {
  height: 25px;
}

.game-logo {
  z-index: 1;
  position: relative;
}

.user-avatar {
  position: relative;
  bottom: 0px;
  z-index: 0;
}

.header-text {
  position: relative;
  bottom: 20px;
  font-size: 25px;
  font-weight: 900;
  margin-top: 25px;
}

.manage-subscription-info {
  font-size: 13px;
}

.yellow-bg {
  background: #FFCB05;
}

.black-bg {
  background: #000;
}

.yellow-border {
  border: 1px solid #FFCB05;
}

.manage-subscription-container-mtn {
  border-radius: 29px;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
}

.manage-subscription-container, .unsubscribe-container {
  border-radius: 14px;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
}

.unsubscribe-container {
  border: v-bind('buttonBorder')
}

.vip-container {
  position: absolute;
  top: 20px;
  right: 10px;
}

.vip-logo {
  width: 100px;
}

.price-point-text {
  margin-left: 2px;
  font-family: 'Poppins Regular', sans-serif;
}

</style>
